import { useState } from "react";

import MovieCard from "./components/MovieCard";

import './App.css';
import SearchIcon from "./search.svg";

// Import API credentials
const config = {
  apiurl: process.env.REACT_APP_API_URL,
  apikey: process.env.REACT_APP_API_KEY,
}

function App() {
  // States
  const [movies, setMovies] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  // OMDB API fetch
  const searchMovies = async (title) => {
    const response = await fetch(`${config.apiurl}?apikey=${config.apikey}&s=${title}`);
    const data = await response.json();

    setMovies(data.Search);
  }
  

  return (
    <div className="app">
      <h1>Movies I've seen</h1>

      <div className="search">
        <input
          placeholder="Search for movies"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        <img
          src={SearchIcon}
          alt="search"
          onClick={() => searchMovies(searchTerm)}
        />
      
      </div>

      {movies?.length > 0
        ? (
            <div className="container">
              {movies.map((movie) => (
                <MovieCard movie={movie}/>
              ))}
            </div>
          ) : (
            <div className="empty">
              <h2>No movies found</h2>
            </div>
        )}
    </div>
  );
}

export default App;
